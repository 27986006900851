import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Typist from 'react-typist';

import { rhythm } from '../../utils/typography';

class Bio extends React.Component {
  render() {
    return (
      <StaticQuery
        query={bioQuery}
        render={data => {
          const { author } = data.site.siteMetadata;
          return (
            <div
              style={{
                display: `flex`,
                marginBottom: rhythm(3.5)
              }}
            >
              <Image
                fixed={data.avatar.childImageSharp.fixed}
                alt={author}
                style={{
                  marginRight: rhythm(1 / 2),
                  marginBottom: 0,
                  minWidth: 50,
                  borderRadius: `100%`
                }}
                imgStyle={{
                  borderRadius: `50%`
                }}
              />
              <div>
                <p className="mb-0">
                  Hi, I am <a href="https://hiteshkumar.dev">{author}</a>.
                </p>
                <Typist
                  cursor={{
                    show: true,
                    blink: true,
                    element: '|',
                    hideWhenDone: false
                  }}
                >
                  <span>I build things.</span>
                  <Typist.Backspace count={16} delay={2000} />
                  <span> I break things.</span>
                  <Typist.Backspace count={16} delay={2000} />
                  <span> I ♥ JavaScript.</span>
                  <Typist.Backspace count={16} delay={2000} />
                  <span>
                    {' '}
                    I <span style={{ textDecoration: `line-through` }}>
                      ♥
                    </span>{' '}
                    JavaScript.
                  </span>
                </Typist>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/avtaar.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        siteUrl
      }
    }
  }
`;

export default Bio;
